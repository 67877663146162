import { Form, Formik, FormikValues } from 'formik';
import DefaultFormikInput from '../../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import React, { useState } from 'react';
import { IntegrationsEnum } from '../../../../../../enums/IntegrationsEnum';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Inventory } from '../../../../../../models/Inventory';
import { integrations } from '../../../../../../constants/Integrations';
import AppButton from '../../../../../../ui/Buttons/AppButton/AppButton';
import { setInventoryCustomPriceRequest } from '../../../../../../@api/User';
import { HttpSuccessResponse } from '../../../../../../@api/Responses/HttpSuccessResponse';
import { Message, useToaster } from 'rsuite';
import { ERROR_DURATION, SUCCESS_DURATION } from '../../../../../../constants/Constants';
import { EventIntegrationStatus } from '../../../../../../enums/EventIntegrationStatus';
import { Link } from 'react-router-dom';

interface IIntegrationFormProps {
    inventory: Inventory;
    currency: string;
    customPrice: number | null;
    integrationPrice: number;
    integrationType: IntegrationsEnum,
    integrationStatus: EventIntegrationStatus,
    onUpdatedCallback?: () => void,
    showLabel?: boolean
}

export const EventIntegration: React.FC<IIntegrationFormProps> = ({
    inventory,
    currency,
    customPrice,
    integrationPrice,
    integrationType,
    integrationStatus,
    showLabel,
    onUpdatedCallback
}) => {
    const toaster = useToaster();
    const [loading, setLoading] = useState(false);
    const onChangeValues = async (values: FormikValues) => {
        setLoading(true);

        let message;
        let type: 'error' | 'success' = 'error';
        try {
            const res = await setInventoryCustomPriceRequest(Number(inventory.id), integrationType, values.customPrice);
            const isSuccess = res instanceof HttpSuccessResponse;
            type = isSuccess ? 'success' : 'error';
            message = res?.message;
            if (isSuccess) {
                onUpdatedCallback && onUpdatedCallback();
            }
            if (!message) {
                message = isSuccess ? 'Successfully updated' : 'Custom'
            }
        } catch (e: any) {
            message = e?.message;
        }

        setLoading(false);
        toaster.push(<Message showIcon type={type} closable>{message}</Message>, {
            duration: type === 'error' ? ERROR_DURATION : SUCCESS_DURATION
        });
    }

    const currencyIcon = getSymbolFromCurrency(currency ?? inventory.currency_symbol);
    const placeholderPrice = (+(+integrationPrice > 0 ? integrationPrice : inventory.unit_amount)).toFixed(2);
    const label = integrations.find(i => i.key === integrationType)?.inputName;

    let block;
    const formClasses = showLabel
        ? 'd-flex flex-nowrap flex-row align-items-center justify-content-end gap-2'
        : 'price-container with-prefix';

    switch (integrationStatus) {
        case EventIntegrationStatus.ACTIVE:
            block = (
                <Formik
                    initialValues={{customPrice: customPrice !== null ? customPrice.toFixed(2) : null}}
                    onSubmit={onChangeValues}>
                    <Form className={formClasses}>
                        {showLabel && <span>{label}</span>}
                        <DefaultFormikInput name={'customPrice'}
                                            prefix={currencyIcon}
                                            className={'price-input' + (showLabel ? 'my-1' : '')}
                                            placeholder={placeholderPrice}
                                            autocomplete={'off'}
                        ></DefaultFormikInput>
                        <AppButton color={'red'} text={'Set'} type={'submit'} size={'sm'}
                                   isLoading={loading}></AppButton>
                    </Form>
                </Formik>
            );
            break;
        case EventIntegrationStatus.LISTING_NOT_MAPPED:
            block = (
                <>
                    Listing is not mapped<br />
                    <Link className={'text-info'} to={'/user/sell/edit-ticket/' + inventory.id + '#ticombo'}>Edit listing</Link>
                </>
            );
            break;
        case EventIntegrationStatus.LISTING_PROCESSING:
            block = (
                <>
                    Listing is processing
                </>
            );
            break;
        case EventIntegrationStatus.LISTING_REMOVED:
            block = (
                <>
                    Listing removed
                </>
            );
            break;
        case EventIntegrationStatus.EVENT_NOT_MAPPED:
            block = (
                <>
                    Event is not available
                    {/*<br /><span className={'text-info text-decoration-underline pointer'} onClick={() => null}>Request event.</span>*/}
                </>
            );
            break;
        case EventIntegrationStatus.TICOMBO_PUSH_FAILED:
            block = (
                <b className={'text-red'}>
                    Push to Ticombo failed
                </b>
            );
            break;
        case EventIntegrationStatus.TICOMBO_PUSH_DISABLED:
            block = (
                <>
                    Disabled<br/>
                    <Link className={'text-info'} to={'/user/sell/edit-ticket/' + inventory.id + '#ticombo'}>Enable</Link>
                </>
            );
            break;
        default:
            block = (
                <>
                    N/A
                </>
            )
    }

    return block;
}
