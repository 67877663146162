import { FC, useEffect, useState } from 'react';
import { getSeries } from '../../../@api/Series';
import { HttpSuccessResponse } from '../../../@api/Responses/HttpSuccessResponse';
import { P1Category } from '../../../models/Category';
import { get } from '../../../@api/Categories';
import AppSelect from '../../../ui/Inputs/AppSelect/AppSelect';
import { EventPageFilters } from '../../../pages/Admin/EventsPage/EventsPage';
import { FormikFormChange } from '../../../@interfaces/FormikHelpers';

type Props = {
    filters: EventPageFilters,
    onFormChange?: FormikFormChange<EventPageFilters>
}
const P1EventCategoryFilter: FC<Props> = ({filters, onFormChange}) => {

    const [series, setSeries] = useState<string[]>([]);
    const [categories, setCategories] = useState<P1Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number>(0);
    const [selectedSeries, setSelectedSeries] = useState<string>(filters.series || '');
    const [selectedOperator, setSelectedOperator] = useState<string>(filters?.operator || '');

    useEffect(() => {
        get().then((res) => setCategories(res.data));
    }, []);

    useEffect(() => {
        getSeries(selectedCategory ?? undefined).then((data) => {
            if (data instanceof HttpSuccessResponse) {
                setSeries(data.data);
            }
        });
    }, [selectedCategory]);

    useEffect(() => {
        if (!filters.category_id) {
            setSelectedCategory(0);
        }
        if (!filters.series) {
            setSelectedSeries('');
        }
        if (!filters.operator) {
            setSelectedOperator('');
        }
    }, [filters]);

    const handleCategoryChange = (value: string | number) => {
        setSelectedCategory(+value);
        setSelectedSeries('');
        onFormChange && onFormChange({
            ...filters,
            category_id: +value,
            series: ''
        });
    };

    return (
        <>
            <div className="events-page__filters__component">
                <AppSelect
                    name={'category_id'}
                    changeCallback={handleCategoryChange}
                    value={selectedCategory}
                    items={[
                        {value: 0, name: 'All Categories'},
                        ...categories.map(c => ({
                            value: c.id,
                            name: c.name
                        }))]}
                />
            </div>
            <div className="events-page__filters__component">
                <AppSelect
                    name={'series'}
                    value={selectedSeries}
                    changeCallback={setSelectedSeries}
                    items={[
                        {value: '', name: 'All Series'},
                        ...series.map(option => ({
                            value: option,
                            name: option
                        }))]}
                />
            </div>
            <div className="events-page__filters__component">
                <AppSelect
                    name={'operator'}
                    value={selectedOperator}
                    changeCallback={setSelectedOperator}
                    items={[
                        {value: '', name: 'All Events'},
                        {value: '=', name: 'Unmapped'},
                        {value: '<>', name: 'Mapped'}
                    ]}
                />
            </div>
        </>
    );
};

export default P1EventCategoryFilter;
