import React, {FC, useEffect, useState} from "react";
import {Container, Tab, Tabs} from "react-bootstrap";
import "./EventsPage.scss";
import usePagination from "../../../helpers/UsePagination";
import {acMilanEventsPaginateRequest, p1EventsPaginateRequest, sbEventsPaginateRequest} from "../../../@api/Event";
import {P1Event, SbEvent} from "../../../models/Event";
import EventsComponent from "../../../components/EventsPage/EventsComponent/EventsComponent";
import SbEventsTable from "../../../components/EventsPage/SbEventsTable/SbEventsTable";
import P1EventsTable from "../../../components/EventsPage/P1EventsTable/P1EventsTable";
import P1EventCategoryFilter from "../../../components/EventsPage/P1EventCategoryFilter/P1EventCategoryFilter";
import {Field} from "formik";
import {performersNotPaginated} from "../../../@api/Performer";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {AcMilanEvent} from "../../../models/AcMilanEvent";
import AcMilanEventsTable from "../../../components/EventsPage/AcMilanEvents/AcMilanEvents";
import {heightTableStyle} from "../../../helpers/SpecificStyles";

export type EventPageFilters = {
    id?: string,
    name?: string,
    venue?: string,
    city?: string,
    category?: string,
    category_id?: number,
    series?: string,
    operator?: string,
    from_date: Date | null,
    to_date?: Date | null,
}

const EventsPage: FC = () => {
    const [sbEventPage, setSbEventPage] = useState<number>(1);
    const [p1EventSelectedPage, setP1EventSelectedPage] = useState<number>(1);
    const [acMilanEventSelectedPage, setAcMilanEventSelectedPage] = useState<number>(1);

    const todayDate = () => {
        const date = new Date();
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;

        return new Date(date.getTime() - userTimezoneOffset);
    };

    const p1EventFilterInitial: EventPageFilters = {
        id: '',
        name: '',
        venue: '',
        city: '',
        category: '',
        category_id: undefined,
        series: '',
        operator: '',
        from_date: todayDate(),
        to_date: null,
    };

    const sbEventFilterInitial = {
        id: '',
        name: '',
        venue: '',
        city: '',
        performer: '',
        mapped: '',
        from_date: todayDate(),
        to_date: null,
        with_inventories: true,
    };

    const acMilanEventFilterInitial = {
        id: '',
        name: '',
        venue: '',
        city: '',
        operator: '=',
        from_date: todayDate(),
        to_date: null,
    };

    const [sbEventFilterValues, setSbEventFilterValues] = useState(sbEventFilterInitial);
    const [p1EventFilterValues, setP1EventFilterValues] = useState(p1EventFilterInitial);
    const [acMilanFilterValues, setAcMilanEventFilterValues] = useState(acMilanEventFilterInitial);

    const paginationSbEvent = usePagination<SbEvent>(sbEventsPaginateRequest);
    const paginationP1Event = usePagination<P1Event>(p1EventsPaginateRequest);
    const paginationAcMilanEvent = usePagination<AcMilanEvent>(acMilanEventsPaginateRequest);

    const sbEventFormValues = [
        [
            {key: "id", value: "Event ID"},
            {key: "name", value: "Event Name"},
            {key: "venue", value: "Event Venue"},
        ],
        [
            {key: "city", value: "Event City"}
        ]
    ];

    const p1EventFormValues = [
        [
            {key: "id", value: "Event ID"},
            {key: "name", value: "Event Name"},
            {key: "venue", value: "Event Venue"},
            {key: "city", value: "Event City"}
        ]
    ];

    const acMilanEventFormValues = [
        [
            {key: "id", value: "Event ID"},
            {key: "name", value: "Event Name"},
            {key: "venue", value: "Event Venue"},
            {key: "city", value: "Event City"}
        ]
    ];

    const eventsServices = [
        {name: 'Unmapped on ANY', value: '!'},
        {name: 'Unmapped on LTG', value: '!ltg'},
        {name: 'Unmapped on TC', value: '!tc'},
        {name: 'Unmapped on SH', value: '!sh'},
        {name: 'Mapped on ALL', value: '='},
        {name: 'Mapped on LTG', value: '=ltg'},
        {name: 'Mapped on TC', value: '=tc'},
        {name: 'Mapped on SH', value: '=sh'}
    ];

    useEffect(() => {
        const elements = document.getElementsByClassName('app-paginated-table-wrapper') as unknown as HTMLElement[];

        for (const element of elements) {
            element.style.height = heightTableStyle('app-paginated-table-wrapper', 200).height;
        }
    }, []);

    const CategoriesFilter = () => {
        return (
            <div className="events-page__filters__component">
                <label>
                    <Field name={'with_inventories'} type='checkbox'></Field>
                    With Inventories only
                </label>
            </div>
        )
    };

    const AcMilanEventsCustomFilter = () => {
        return (
            <>
                <div className="events-page__filters__component">
                    <Field name={'operator'} as="select"
                           style={{display: 'block', backgroundColor: 'white'}}>

                        <option key={'Unmapped'} value="=">Unmapped</option>
                        <option key={'Mapped'} value="<>">Mapped</option>
                    </Field>
                </div>
            </>
        )
    };

    const TopCustomFilters = () => {
        return (
            <>
                <div className="events-page__filters__component">
                    <AppSmartSearch
                        name={'performer'}
                        placeholder={'Event Performer'}
                        requestFunction={performersNotPaginated}
                        valuesKey={'name'}
                    />
                </div>
                <div className="events-page__filters__component">
                    <Field name={'mapped'} as="select">
                        <option key={'emptyEventsServices'} value="">All Events</option>
                        {
                            eventsServices.map(eventsService => (
                                <option key={eventsService.name}
                                        value={eventsService.value}>{eventsService.name}</option>
                            ))
                        }
                    </Field>
                </div>
            </>
        );
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Events</h1>
            <Tabs defaultActiveKey={'sbEvents'}>
                <Tab title={'Skybox Events'} eventKey={'sbEvents'}>
                    <EventsComponent
                        pagination={paginationSbEvent}
                        filterInitial={sbEventFilterInitial}
                        formValues={sbEventFormValues}
                        filterValues={sbEventFilterValues}
                        setFilterValues={setSbEventFilterValues}
                        selectedPage={sbEventPage}
                        setSelectedPage={setSbEventPage}
                        tableComponent={
                            <SbEventsTable
                                pagination={paginationSbEvent}
                                filterValues={sbEventFilterValues}
                                selectedPage={sbEventPage}/>
                        }
                        pageType={'sb'}
                        customFilters={CategoriesFilter}
                        topCustomFilters={<TopCustomFilters/>}
                    />
                </Tab>
                <Tab title={'P1 Hospitality Events'} eventKey={'p1_events'}>
                    <EventsComponent
                        key={'component-2'}
                        pagination={paginationP1Event}
                        filterInitial={p1EventFilterInitial}
                        formValues={p1EventFormValues}
                        selectedPage={p1EventSelectedPage}
                        setSelectedPage={setP1EventSelectedPage}
                        filterValues={p1EventFilterValues}
                        setFilterValues={setP1EventFilterValues}
                        tableComponent={
                            <P1EventsTable
                                key={'table-2'}
                                pagination={paginationP1Event}
                                selectedPage={p1EventSelectedPage}
                                filterValues={p1EventFilterValues}/>
                        }
                        pageType={'p1'}
                        customFilters={(formChange) => (
                            <P1EventCategoryFilter filters={p1EventFilterValues} onFormChange={formChange}/>
                        )}/>
                </Tab>
                <Tab title={'AC Milan Events'} eventKey={'ac_milan__events'}>
                    <EventsComponent
                        key={'component-2'}
                        pagination={paginationAcMilanEvent}
                        filterInitial={acMilanEventFilterInitial}
                        formValues={acMilanEventFormValues}
                        selectedPage={acMilanEventSelectedPage}
                        setSelectedPage={setAcMilanEventSelectedPage}
                        filterValues={acMilanFilterValues}
                        setFilterValues={setAcMilanEventFilterValues}
                        tableComponent={
                            <AcMilanEventsTable
                                key={'table-2'}
                                pagination={paginationAcMilanEvent}
                                selectedPage={acMilanEventSelectedPage}
                                filterValues={acMilanFilterValues}/>
                        }
                        customFilters={AcMilanEventsCustomFilter}/>
                </Tab>
            </Tabs>
        </Container>
    )
}

export default EventsPage;
