import React, {FC, useEffect, useState} from "react";
import {acMilanEventsPatch, p1EventsPatch, sbEventsPaginateRequest} from "../../../@api/Event";
import {SbEvent} from "../../../models/Event";
import "./EditEventForm.scss";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import usePagination from "../../../helpers/UsePagination";
import EventsComponent from "../EventsComponent/EventsComponent";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";

interface EditFormProps {
    event_id: number;
    sb_event?: SbEvent;
    showHandle: () => void
    name: string;
    venue: string;
    city: string;
    date: string;
    integration_type?: string;
}

const EditEventForm: FC<EditFormProps> = (
    {
        event_id,
        showHandle,
        name,
        venue,
        city,
        date,
        sb_event,
        integration_type = 'p1'
    }
) => {
    const tableHeaders = [
        'ID',
        'Name',
        'Venue',
        'City',
        'Date',
        ''
    ];
    const sbEventFilterInitial = {
        id: '',
        name: '',
        venue: '',
        city: '',
        performer: '',
        mapped: '',
        from_date: new Date(),
        to_date: null,
        with_inventories: false,
    };
    const sbEventFormValues = [
        [
            {key: "id", value: "Search events by ID"},
            {key: "name", value: "Search events by name"},
            {key: "venue", value: "Search events by venue"},
            {key: "city", value: "Search events by city"}
        ]
    ];

    const [sbEventFilterValues, setSbEventFilterValues] = useState(sbEventFilterInitial);
    const [sbEventPage, setSbEventPage] = useState<number>(1);
    const [selectedEvent, setSelectedEvent] = useState<SbEvent | null>(sb_event ?? null);

    const pagination = usePagination<SbEvent>(sbEventsPaginateRequest);

    useEffect(() => {
        const modal = document.getElementsByClassName('modal-dialog')[0];

        modal.classList.add('map');
    }, []);

    const mapDefaultResponseHandler = (res: HttpFailureResponse | HttpSuccessResponse) => {
        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
            return;
        }

        showHandle();
    };

    const edit = (id: number | null) => {
        const request = () => {
            switch (integration_type) {
                case 'p1':
                    return p1EventsPatch(event_id, {'sb_event_id': id});
                case 'ac_milan':
                    return acMilanEventsPatch(event_id, {'sb_event_id': id});
                default:
                    return null;
            }
        };

        request()?.then((res) => {
            mapDefaultResponseHandler(res);
        });
    };

    return (
        <div className="edit-event">
            <div className={"edit-event-title"}>
                <h5>{name},</h5>
                <h5>{venue}, {city},</h5>
                <h5>{date}</h5>
            </div>
            <EventsComponent
                pagination={pagination}
                filterInitial={sbEventFilterInitial}
                formValues={sbEventFormValues}
                filterValues={sbEventFilterValues}
                setFilterValues={setSbEventFilterValues}
                selectedPage={sbEventPage}
                setSelectedPage={setSbEventPage}
                tableComponent={
                    <AppTable
                        columns={tableHeaders}
                        pagination={{
                            lastPage: pagination.lastPage,
                            currentPage: pagination.currentPage,
                            total_items: pagination.totalItems
                        }}
                        onPageChange={(page, perPage) => {
                            pagination.setCurrentPage(page);
                            pagination.setPerPage(perPage);
                            pagination.paginate(sbEventFilterValues);
                        }}
                        withAutoHeight={false}
                    >
                        <>
                            {selectedEvent
                                ? <tr className="edit-event__selected">
                                    <td>
                                        <div className={"sb-events-page__td-id"}>
                                            {selectedEvent.event_id}
                                        </div>
                                    </td>
                                    <td>{selectedEvent.name}</td>
                                    <td>{selectedEvent.venue}</td>
                                    <td>{selectedEvent.city}</td>
                                    <td>{dateToLocaleString(selectedEvent.occurs_at)}</td>
                                    <td>
                                        <button
                                            className="auth-wrapper__submit-btn mt-3 edit-event__white_button"
                                            type="button"
                                            onClick={() => edit(null)}
                                        >
                                            Unmap & Save
                                        </button>
                                    </td>
                                </tr>
                                : <></>
                            }
                            {pagination.items.length
                                ? pagination.items?.map((event, index) =>
                                    !(selectedEvent?.event_id === event.event_id) ?
                                        <React.Fragment key={'SbEvents' + index}>
                                            <tr data-row={index}>
                                                <td>
                                                    <div className={"sb-events-page__td-id"}>
                                                        {event.event_id}
                                                    </div>
                                                </td>
                                                <td>{event.name}</td>
                                                <td>{event.venue}</td>
                                                <td>{event.city}</td>
                                                <td>{dateToLocaleString(event.occurs_at)}</td>
                                                <td>
                                                    <button
                                                        className="auth-wrapper__submit-btn"
                                                        type="button"
                                                        onClick={() => edit(event.id)}
                                                    >
                                                        Map & Save
                                                    </button>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                        : <></>
                                )
                                :
                                <tr className={"events-page__table__tr"}>
                                    <td className={"text-center"} colSpan={8}>No events</td>
                                </tr>
                            }
                        </>
                    </AppTable>
                }
                customFilters={() => <></>}
            />
        </div>
    )
}

export default EditEventForm;
